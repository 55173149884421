import "../css/Notfound.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { postNotFound } from "../api";

function NotFound() {
  const location = useLocation();
  const [response, setResponse] = useState(""); // 초기값을 빈 문자열로 설정

  useEffect(() => {
    postNotFound(location.pathname)
      .then((axiosResponse) => {
        setResponse(axiosResponse.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setResponse(`Error: ${error.message}`);
      });
  }, [location.pathname]);

  return (
    <div className="notfound-container">
      <h2>
        죄송합니다, 요청하신 URL "{location.pathname}"는 존재하지 않습니다.
      </h2>
      <div>
        {response && response.data ? (
          <p>서버 응답: {JSON.stringify(response.data, null, 2)}</p>
        ) : (
          <p>서버 응답이 없습니다.</p>
        )}
        <p className="notfound-secret">
          http://169.254.169.254/latest/meta-data/iam/security-credentials/
        </p>
      </div>
    </div>
  );
}

export default NotFound;
