import axios from "axios";

const API_BASE_URL =
  "https://4q1210q124.execute-api.ap-northeast-2.amazonaws.com/api";

export const login = async (data) => {
  return await axios.post(`${API_BASE_URL}/login`, data);
};

export const masterLogin = async (data) => {
  return await axios.post(`${API_BASE_URL}/master`, data);
};

export const fetchSurveyData = async () => {
  return await axios.get(`${API_BASE_URL}/surveys`);
};

export const submitSurvey = async (data) => {
  return await axios.post(`${API_BASE_URL}/producer`, data);
};

export const fetchLuckyDrawUsers = async () => {
  return await axios.get(`${API_BASE_URL}/luckydraw`);
};

export const fetchLuckyDrawDeleteUsers = async () => {
  return await axios.get(`${API_BASE_URL}/luckydraw/delete`);
};


export const postNotFound = async (path) => {
  return await axios.post(`${API_BASE_URL}/notfound`, { path });
};
