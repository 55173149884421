import { useParams, useNavigate } from "react-router-dom";
import React from "react";
import "../css/Thanks.css";
import { datadogRum } from "@datadog/browser-rum";

function Thanks() {
  // datadogRum.clearUser();
  const navigate = useNavigate();
  const { userName } = useParams();

  return (
    <div className="thanks-container">
      <img src="/datadog-logo.png" alt="Thanks" className="image-animation" />
      <div className="message">
        <h1>{userName}님, 설문 감사합니다!</h1>
        <button
          className="back-button"
          onClick={() => {
            localStorage.removeItem("authToken");
            navigate("/");
          }}
        >
          로그인 페이지로 돌아가기
        </button>
      </div>
    </div>
  );
}

export default Thanks;
