import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";
import { login } from "../api";
import { datadogRum } from "@datadog/browser-rum";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(""); // 에러 메시지 상태 추가

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      alert("이름과 간단한 비밀번호를 모두 입력해주세요!");
      return;
    }

    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await login(data);

      if (response.data.status === true) {
        const userName = response.data.username;
        localStorage.setItem("authToken", true);
        datadogRum.clearGlobalContext();
        datadogRum.setUser({ name: userName });
        navigate(`/survey/${userName}`);
        setMessage("로그인 성공");
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("API 요청 오류:", error);
      if (error.response && error.response.status === 401) {
        setMessage("패스워드가 틀렸습니다.");
      } else {
        setMessage("서버 오류가 발생했습니다.");
      }
    }
  };

  return (
    <div className="login-container">
      <img src="./datadog-logo.png" alt="Datadog Logo" className="logo" />
      <div className="login-form">
        <h1>Datadog Webinar</h1>
        <h5>사전 설문조사</h5>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="웨비나 접속 닉네임을 입력하세요!"
            className="login-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="간단한 신규 비밀번호를 입력하세요!"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="login-button">
            Log in
          </button>
        </form>
        {message && <div className="error-message">{message}</div>}
      </div>
    </div>
  );
}

export default Login;
