import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem("authToken");

  if (!isAuthenticated) {
    // 인증되지 않은 경우, 로그인 페이지로 리디렉트
    return <Navigate to="/" />;
  }
  return children;
}

export default ProtectedRoute;
