import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./app/Login";
import Survey from "./app/Survey"; // 설문조사 페이지 컴포넌트를 import 해야 합니다.
import Master from "./app/Master";
import LuckyDraw from "./app/LuckyDraw";
import NotFound from "./app/Notfound";
import Thanks from "./app/Thanks";
import ProtectedRoute from "./app/ProtectedRoute";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "e54f3382-7a3a-4da0-b66d-5d3fd9088ec1",
  clientToken: "pub02eafbe80b39f624cf62175be278edec",
  site: "datadoghq.com",
  service: "webinar_survey",
  env: "prod",
  // Specify a version number to identify the deployed version of your application in Datadog
  version: "0.0.1",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
  allowedTracingUrls: [
    "https://4q1210q124.execute-api.ap-northeast-2.amazonaws.com/",
    "",
  ],
});

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/master" element={<Master />} />
          <Route
            path="/survey/:userName"
            element={
              <ProtectedRoute>
                <Survey />
              </ProtectedRoute>
            }
          />
          <Route
            path="/thanks/:userName"
            element={
              <ProtectedRoute>
                <Thanks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/luckydraw"
            element={
              <ProtectedRoute>
                <LuckyDraw />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
