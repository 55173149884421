import React, { useState, useEffect, useRef } from "react";
import { fetchLuckyDrawUsers, fetchLuckyDrawDeleteUsers } from "../api";
import "../css/LuckyDraw.css";

const LuckyDraw = () => {
  const [users, setUsers] = useState([]);
  const [currentCell, setCurrentCell] = useState(-1);
  const [isDrawing, setIsDrawing] = useState(false);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [winner, setWinner] = useState(null);
  const intervalRef = useRef(); // intervalId를 저장하기 위한 ref

  useEffect(() => {
    fetchLuckyDrawUsers()
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    const chunkSize = 7;
    const newDisplayUsers = [];

    for (let i = 0; i < users.length; i += chunkSize) {
      newDisplayUsers.push(users.slice(i, i + chunkSize));
    }

    setDisplayUsers(newDisplayUsers);
  }, [users]);

  const startDrawing = () => {
    setIsDrawing(true);

    intervalRef.current = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * users.length);
      setCurrentCell(randomIndex);
    }, 10);
  };

  const stopDrawing = () => {
    clearInterval(intervalRef.current);
    setIsDrawing(false);
    setWinner(users[currentCell]);
  };

  const deleteUser = () => {
    fetchLuckyDrawDeleteUsers()
      .then((response) => {
        console.log("User deleted successfully:", response.data);
        // 필요한 경우 상태 업데이트
        setUsers(response.data.users); // 서버에서 새로 업데이트된 유저 리스트를 받아서 반영
        window.location.reload(); // 페이지 새로고침
      })
      .catch((error) => {
        console.error("Error deleting user: ", error);
      });
  };

  const usernameColor = "#8A2BE2"; // 밝은 보라색

  return (
    <div className="lucky-draw">
      <h1 className="lucky-draw-title">커피 쿠폰을 추첨할게요😊</h1>

      {winner ? (
        <p className="winner-message">
          축하합니다. {winner.username} 님 당첨 되셨습니다.
        </p>
      ) : (
        <p className="winner-message">
          <p>공격 성공 암호: NewJeans</p>
        </p>
      )}
      <table className="lucky-draw-table">
        <tbody>
          {displayUsers.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((user, columnIndex) => (
                <td
                  key={columnIndex}
                  className={`lucky-draw-cell ${
                    currentCell === columnIndex + rowIndex * 7 ? "yellow" : ""
                  }`}
                  style={{
                    backgroundColor:
                      currentCell === columnIndex + rowIndex * 7
                        ? usernameColor
                        : "",
                  }}
                >
                  {user.username}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isDrawing ? (
        <button className="lucky-draw-button" onClick={stopDrawing}>
          정지
        </button>
      ) : (
        <button className="lucky-draw-button" onClick={startDrawing}>
          시작
        </button>
      )}
      <button className="lucky-draw-button" onClick={deleteUser}>
          유저 삭제
        </button>
    </div>
  );
};

export default LuckyDraw;
