import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import "../css/Survey.css";
import { fetchSurveyData, submitSurvey } from "../api";
import { datadogRum } from "@datadog/browser-rum";

function Survey() {
  const navigate = useNavigate();
  const { userName } = useParams();
  const [surveyData, setSurveyData] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchSurveyData()
      .then((response) => {
        setSurveyData(response.data.surveys);
        setLoading(false);
      })
      .catch((error) => {
        console.error("설문 데이터를 불러오는 중 오류가 발생했습니다:", error);
        setLoading(false);
      });
  }, []);

  const handleOptionChange = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // 모든 질문에 대한 답변을 선택했는지 확인
    const allQuestionsAnswered = Object.keys(surveyData[0])
      .filter((key) => key.startsWith("q"))
      .every((questionKey) => answers.hasOwnProperty(questionKey));

    if (!allQuestionsAnswered) {
      alert("모든 질문에 답변을 선택해주세요.");
      return;
    }
    try {
      // POST 요청을 보낼 엔드포인트 URL을 지정
      const questionAndAnswerPairs = Object.keys(surveyData[0])
        .filter((key) => key.startsWith("q"))
        .map((questionKey) => ({
          [questionKey]: surveyData[0][questionKey],
          [`a${questionKey.substring(1)}`]: answers[questionKey],
        }));

      // POST 요청을 보낼 데이터를 준비
      const surveyResponse = {
        userName: userName,
        questions: questionAndAnswerPairs,
      };
      console.log(JSON.stringify(surveyResponse));

      // POST 요청
      const response = await submitSurvey(surveyResponse);
      if (response.status === 200) {
        const formattedSurvey = surveyResponse.questions.reduce(
          (acc, question) => {
            const answerKey = Object.keys(question).find((key) =>
              key.startsWith("a")
            );
            if (answerKey) {
              acc[answerKey] = question[answerKey];
            }
            return acc;
          },
          {}
        );

        datadogRum.setGlobalContextProperty("global_context", {
          survey: formattedSurvey,
        });
        navigate(`/thanks/${userName}`);
      } else {
        alert("답변 제출 중 오류가 발생했습니다.");
      }
    } catch (error) {
      console.error("답변 제출 중 오류가 발생했습니다:", error);
      alert("답변 제출 중 오류가 발생했습니다.");
    }
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">로딩 중...</span>
        </Spinner>
      </div>
    );
  }

  // 질문과 보기를 렌더링하는 로직
  const renderQuestions = () => {
    // surveyData 배열의 첫 번째 항목에서 질문 키들을 추출합니다.
    const questions = Object.keys(surveyData[0]).filter((key) =>
      key.startsWith("q")
    );

    return questions.map((questionKey) => {
      const questionText = surveyData[0][questionKey];

      // 현재 질문 번호를 추출합니다. 예를 들어, 'q1'에서 '1'을 가져옵니다.
      const questionNumber = questionKey.match(/\d+/)[0];

      // 답변 항목을 추출하고 정렬합니다.
      const answerKeys = Object.keys(surveyData[0])
        .filter((key) => key.startsWith(`a${questionNumber}-`))
        .sort(); // 키를 기준으로 정렬

      return (
        <div className="mb-4" key={questionKey}>
          {" "}
          {/* margin-bottom을 조정하여 간격을 늘림 */}
          <p className="fw-bold text-start">{questionText}</p>{" "}
          {/* text-start 클래스로 좌측 정렬 */}
          {answerKeys.map((answerKey) => (
            <div key={answerKey} className="form-check text-start">
              {" "}
              {/* text-start 클래스로 좌측 정렬 */}
              <input
                className="form-check-input"
                type="radio"
                name={questionKey}
                id={answerKey}
                value={surveyData[0][answerKey]}
                checked={answers[questionKey] === surveyData[0][answerKey]}
                onChange={() =>
                  handleOptionChange(questionKey, surveyData[0][answerKey])
                }
              />
              <label className="form-check-label" htmlFor={answerKey}>
                {surveyData[0][answerKey]}
              </label>
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <div className="survey-container">
      <h3>안녕하세요, {userName}님!</h3>
      <form onSubmit={handleSubmit}>
        {renderQuestions()}
        <button type="submit">제출</button>
      </form>
    </div>
  );
}

export default Survey;
