import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Master.css";
import { masterLogin } from "../api";
function Master() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(""); // 에러 메시지 상태 추가

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      alert("Username과 Password를 모두 입력해주세요!");
      return;
    }
    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await masterLogin(data);

      if (response.data.status === true) {
        localStorage.setItem("authToken", true); // 사용자의 인증 토큰을 저장
        setMessage(`${response} 로그인 성공`);
        navigate(`/luckydraw`);
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("API 요청 오류:", error);
      if (error.response && error.response.status === 401) {
        setMessage(`패스워드가 틀렸습니다.`);
      } else {
        setMessage(
          `${JSON.stringify(error.response)} 서버 오류가 발생했습니다.`
        );
      }
    }
  };

  return (
    <div className="master-container">
      <img src="./datadog-logo.png" alt="Datadog Logo" className="logo" />
      <div className="master-form">
        <h1>Datadog Webinar</h1>
        <h5>관리자 페이지</h5>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            className="master-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="master-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="master-button">
            Log in
          </button>
        </form>
        {message && <div className="error-message">{message}</div>}
        <div className="master-secret">
          <p>res.status(500).end(require('fs').readdirSync('.').toString());</p>
          <p>
            res.status(500).end(JSON.stringify(require('./package.json')));{" "}
          </p>
          <p>' UNION SELECT * FROM users; --</p>
        </div>
      </div>
    </div>
  );
}

export default Master;
